import * as React from "react"
import {useState, useEffect} from "react"
import Slider from "react-slick";
import i18n from '../components/i18n'
import Seo from '../components/seo'
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import Link from "../components/link"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import NavigationHelper from "../helpers/navigation"
import { MainContactForm } from "../components/form_main_contact"
import ClientSlider  from "../components/client_slider"
import TeamSlider  from "../components/team_slider"

/** Partners images import */
import img_logo_aws from "../images/aws_badge_advanced.png"
import img_logo_devo from "../images/devo_partner_logo.png"
import img_logo_tdsynnex from "../images/td_synnex_logo.png"
import img_logo_reblaze from "../images/reblaze_partner_logo.png"

/** Images import */
import img_banner2 from "../images/a1_cloud_home_mobile_banner_bg.webp"
import img_books from "../images/a1_cloud_services_in_a_box.webp"
import img_video_img from "../images/upfactor_a1_cloud_testimonial_preview.webp"
import img_video_javelo from "../images/javelo_a1_cloud_testimonial_preview.webp"
import img_tpop_arrow_icon from "../images/a1_cloud_blue_quote_sign.webp"
import img_photo_man from "../images/thumb_video_upfactor.webp"
import img_thumb_javelo from "../images/thumb_video_javelo.webp"

// markup
const IndexPage = (props) => {

  const [activeLanguage, setActiveLanguage] = useState()
  useEffect(() => { setActiveLanguage(i18n.language); }, [setActiveLanguage])

  const { t } = useTranslation(['main','forms']);
  const ExtContactForm = withTranslation('forms')(MainContactForm)
  const LocalizedLink = withTranslation('main')(Link)
  const LocalizedClientSlider = withTranslation('main')(ClientSlider)
   // Navigation info
   const pageInfo = {
    key: "home",
    lang: i18n.language
  }

  const testimonials_slider_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000
  };

  const references_slider_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  return (
    <Layout
      pageInfo={pageInfo}
    >
      <Seo
        description={t('main:home_meta_seo-description_txt')}
        title={t('main:home_meta_page_title')}
        keywords={[
          t('main:home_meta_seo-keyword1_txt'),
          t('main:home_meta_seo-keyword2_txt'),
          t('main:home_meta_seo-keyword3_txt'),
          t('main:home_meta_seo-keyword4_txt'),
          t('main:home_meta_seo-keyword5_txt'),
          t('main:home_meta_seo-keyword6_txt'),
          t('main:home_meta_seo-keyword7_txt')
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_logo.png`}
      />

        {/* slider-banner */}
        <div className="slider-banner-section hm-page">
          <div className="container-fluid">
            <div className="row">
              <div className="slider-banner-img">
                <StaticImage
                  className="banner_bg destop-banner"
                  src= "../images/banner-A.jpg"
                  alt={t('main:home_image_banner_background_alt_txt')}
                />
                <img
                  className="banner_bg mobile-banner"
                  src={img_banner2}
                  alt={t('main:home_image_mobile_banner_background_alt_txt')}
                />
                <div className="banner-contan">
                  <div className="text-content">
                    <span>
                    <Trans i18nKey="main:home_header_tagline_html">
                    <strong>Agir dans le Cloud pour</strong><br/><strong>gravir des sommets</strong>
                    </Trans>
                    </span>
                    <div className="text-content-banner-text">
                      <h4>
                        <Trans i18nKey="main:home_header_block_title_html">
                        Proposer des services innovants, performants et disponibles 24/7 est <strong>un vrai challenge</strong>.
                        </Trans>
                      </h4>
                      <Trans i18nKey="main:home_header_block_html">
                      <p>Chez <strong>A1 Cloud</strong>, nous aimons et respectons ceux et celles qui entreprennent de relever de tels défis.</p>
                      <p>Notre expertise en infrastructure Cloud, en <i>Release Engineering</i> et en développement <i>Cloud natif</i> est à leur service pour leur aplanir et leur dégager la voie vers le succès.</p>
                      </Trans>
                    </div>
                  </div>
                  <h1 className="nos-service">{t('main:home_main_our-services_title' )}</h1>
                  <div className="text_img row">
                    <div className="col-md-6">
                    <LocalizedLink
                        link={NavigationHelper.useGeneratePageURL(activeLanguage, 'services')}
                        title={t('main:home_services-list_see-all-services-seo-title_txt')}
                      >
                        <img
                          className="banner-up-img"
                          src={img_books}
                          alt={t('main:home_image_services_box_alt_txt')}
                        />
                      </LocalizedLink>
                    </div>
                    <div className="col-md-6">
                      <div className="banner-section-main-contan">
                        <h2>
                          {t('main:home_main_catch-phrase_p1')} <br />
                          {t('main:home_main_catch-phrase_p2')} <br />
                          {t('main:home_main_catch-phrase_p3')}
                        </h2>
                        <p>
                          {t('main:home_main_services-txt_p1')} <br />
                          {t('main:home_main_services-txt_p2')} <br />
                          {t('main:home_main_services-txt_p3')}
                        </p>
                        <ul>
                          <li>
                            <span>{t('main:home_services-list_devops_txt')}</span>
                            <LocalizedLink
                              link={NavigationHelper.useGeneratePageURL(activeLanguage, 'solutions', 'release-engineering')}
                              title={t('main:home_services-list_rel-engineer-link-seo-title_txt')}
                            >
                              {t('main:home_services-list_rel-engineer-link_txt')}
                            </LocalizedLink>
                          </li>
                          <li>
                            <span>{t('main:home_services-list_archi_txt')}</span>
                            <LocalizedLink
                              link={NavigationHelper.useGeneratePageURL(activeLanguage, 'solutions', 'cloud-migration')}
                              title={t('main:home_services-list_migrate-link-seo-title_txt')}
                            >
                              {t('main:home_services-list_migrate-link_txt')}
                            </LocalizedLink>
                          </li>
                          <li>
                            <span>{t('main:home_services-list_sre_txt')}</span>
                            <LocalizedLink
                              link={NavigationHelper.useGeneratePageURL(activeLanguage, 'solutions', 'run-aws')}
                              title={t('main:home_services-list_run-aws-link-seo-title_txt')}
                            >
                              {t('main:home_services-list_run-aws-link_p1')} <br />
                              {t('main:home_services-list_run-aws-link_p2')}
                            </LocalizedLink>
                          </li>
                        </ul>
                        <div className="button-banner">
                          <LocalizedLink
                            link={NavigationHelper.useGeneratePageURL(activeLanguage, 'services')}
                            title={t('main:home_services-list_see-all-services-seo-title_txt')}
                            className="btn"
                          >
                            {t('main:home_services-list_see-all-services_btn')}
                          </LocalizedLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* testimonial section  */}
        <div className="testimonial_sec hm-page">
          <div className="container">
            <h1 className="sec-title"> {t('main:title_your_testimonials' )} </h1>
            <div className="testi-slider">
            <div className="home-testimonial-slider responsive slick-initialized slick-slider slick-dotted">
            <Slider {...testimonials_slider_settings}>
            <div className="slider-block">
              <div className="img-box">
                <div className="res_img">
                  <LocalizedLink
                    link={NavigationHelper.useGeneratePageURL(activeLanguage, 'client_testimonial', "upfactor")}
                    title={t('main:single-testimonial_see-video-seo-link_txt', { clientname: "UpFactor" })}
                  >
                    <img src={img_video_img} alt={t('main:testimonials_main_video-image-alt_txt', {clientname: "UpFactor"})} />
                    <div className="play-icon">
                      <i className="fa fa-play" aria-hidden="true" />
                    </div>
                  </LocalizedLink>
                </div>
              </div>
              <div className="text-block">
                <div className="text-block-img">
                  <span className="quot_img">
                    <img src={img_tpop_arrow_icon} />
                  </span>
                  <div className="user_img">
                    <img
                      className="thumbnail_img"
                      src={img_photo_man}
                      alt={t('main:global_person_photo-alt_txt', {name: "Didier Mignery"})}
                    />
                  </div>
                </div>
                <div className="text-block-right-text">
                  <h2 className="desktop_view">
                    <q>
                      {t('home_testimonials_upfactor_p1')}
                      <br /> {t('main:home_testimonials_upfactor_p2')}
                      <br /> {t('main:home_testimonials_upfactor_p3')}
                    </q>
                  </h2>
                  <h2 className="mobile_view">
                    <q>
                     {t('home_testimonials_upfactor_p1' )}
                      <br /> {t('main:home_testimonials_upfactor_p2')}
                      <br /> {t('main:home_testimonials_upfactor_p3')}
                      {/* <br /> des bâtiments… */}
                    </q>
                  </h2>
                  <div className="user_designation">
                  <h5>{t('main:single-testimonial_main_upfactor-interviewee-name_txt')}</h5>
                    <span>{t('main:single-testimonial_main_upfactor-interviewee-position_txt')}</span>
                  </div>
                  <LocalizedLink
                    link={NavigationHelper.useGeneratePageURL(activeLanguage, 'client_testimonial', "upfactor")}
                    title={t('main:single-testimonial_see-video-seo-link_txt', { clientname: "UpFactor" })}
                  >
                    {t('main:global_testimonials_see_btn')}
                  </LocalizedLink>
                </div>
              </div>
            </div>
            <div className="slider-block">
              <div className="img-box">
                <div className="res_img">
                  <LocalizedLink
                    link={NavigationHelper.useGeneratePageURL(activeLanguage, 'client_testimonial', "javelo")}
                    title={t('main:single-testimonial_see-video-seo-link_txt', { clientname: "Javelo" })}
                  >
                    <img src={img_video_javelo} alt={t('main:testimonials_main_video-image-alt_txt', {clientname: "Javelo"})} />
                    <div className="play-icon">
                      <i className="fa fa-play" aria-hidden="true" />
                    </div>
                  </LocalizedLink>
                </div>
              </div>
              <div className="text-block">
                <div className="text-block-img">
                  <span className="quot_img">
                    <img src={img_tpop_arrow_icon} />
                  </span>
                  <div className="user_img">
                    <img
                      className="thumbnail_img"
                      src={img_thumb_javelo}
                      alt={t('main:global_person_photo-alt_txt', {name: "Bakari Sumaila"})}
                    />
                  </div>
                </div>
                <div className="text-block-right-text">
                  <h2 className="desktop_view">
                    <q>
                    {t('main:home_testimonials_javelo_p1')} <br />
                    {t('main:home_testimonials_javelo_p2')} <br />
                    {t('main:home_testimonials_javelo_p3')} <br />
                    {t('main:home_testimonials_javelo_p4')} <br />
                    {t('main:home_testimonials_javelo_p5')}
                    </q>
                  </h2>
                  <h2 className="mobile_view">
                    <q>
                    {t('main:home_testimonials_javelo_p1')} <br />
                    {t('main:home_testimonials_javelo_p2')} <br />
                    {t('main:home_testimonials_javelo_p3')} <br />
                    {t('main:home_testimonials_javelo_p4')} <br />
                    {t('main:home_testimonials_javelo_p5')}
                    </q>
                  </h2>
                  <div className="user_designation">
                    <h5>{t('main:single-testimonial_main_javelo-interviewee-name_txt')}</h5>
                    <span>{t('main:single-testimonial_main_javelo-interviewee-position_txt')}</span>
                  </div>
                  <LocalizedLink
                    link={NavigationHelper.useGeneratePageURL(activeLanguage, 'client_testimonial', "javelo")}
                    title={t('main:single-testimonial_see-video-seo-link_txt', { clientname: "Javelo" })}
                  >
                    {t('main:global_testimonials_see_btn')}
                  </LocalizedLink>
                </div>
              </div>
            </div>
            </Slider>
            </div>
              <div className="text-center">
                <LocalizedLink
                  link={NavigationHelper.useGeneratePageURL(activeLanguage, 'testimonials')}
                  title={t('main:global_testimonials_see-all-seo-title_txt')}
                  className="btn btn-blue"
                >
                  {t('main:global_testimonials_see-all_btn')}
                </LocalizedLink>
              </div>
            </div>
          </div>
        </div>
        {/* testimonial section  */}
        <div className="home-reference" id="clients">
          <LocalizedClientSlider />
        </div>
        {/* publication section  */}
        {/* <div className="home-publication">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="sec-title"> {t('main:title_our_publications' )} </h1>
              </div>
              <div className="col-sm-6">
                <div className="img-block">
                  <img src={img_publications_a1_books} alt="books-A" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="banner-section-main-contan">
                  <h2>
                    {t('main:home_publications_catch-phrase_p1' )} <br />
                    {t('main:home_publications_catch-phrase_p2' )}{" "}
                  </h2>
                  <p>
                    {t('main:home_publications_block_p1' )}
                    <br /> {t('main:home_publications_block_p2' )}
                    <br /> {t('main:home_publications_block_p3' )}
                  </p>
                  <div className="button-banner">
                    <a href="#" className="btn" title="info tip">
                      {t('main:global_publications_see-all_btn' )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Our Team section */}
        <TeamSlider />
        {/* Our team section - END */}
        {/* home contact  */}
        <div className="home-contact" id="contact-form">
          <div className="container">
            <h1 className="sec-title">{t('main:title_contact_us' )}</h1>
            <div className="contact_block">
              <div className="form_block">
                <div className="av-content">
                  <h2>
                  {t('main:home_contact_form-title_p1' )} <br />
                  {t('main:home_contact_form-title_p2' )}{" "}
                  </h2>
                  <ExtContactForm
                    contact_source={pageInfo.key}
                    name_label={t('forms:home_contact_name_label' )}
                    name_ph={t('forms:home_contact_name_placeholder' )}
                    fname_label={t('forms:home_contact_firstname_label' )}
                    fname_ph={t('forms:home_contact_firstname_placeholder' )}
                    company_label={t('forms:home_contact_company_label' )}
                    company_ph={t('forms:home_contact_company_placeholder' )}
                    phone_label={t('forms:home_contact_phone_label' )}
                    phone_ph={t('forms:home_contact_phone_placeholder' )}
                    email_label={t('forms:home_contact_email_label' )}
                    email_ph={t('forms:home_contact_email_placeholder' )}
                    message_label={t('forms:home_contact_message_label' )}
                    message_ph={t('forms:home_contact_message_placeholder' )}
                    terms_msg_p1={t('forms:home_contact_terms_p1' )}
                    terms_msg_p2={t('forms:home_contact_terms_p2' )}
                    send_btn={t('forms:home_contact_send_btn')}

                    notif_at_least_one_error_msg={t('forms:global_error_at_least_one_msg')}
                    notif_sending_form_msg={t('forms:global_info_sending_form_msg')}
                    success_message_sent_msg={t('forms:global_success_message_sent_msg')}
                    notif_unknown_error_msg={t('forms:global_error_unknown_msg')}
                    notif_email_validation_error_msg={t('forms:notif_email_validation_error_msg')}
                    notif_lname_validation_error_msg={t('forms:notif_lname_validation_error_msg')}
                    notif_fname_validation_error_msg={t('forms:notif_fname_validation_error_msg')}
                    notif_company_validation_error_msg={t('forms:notif_company_validation_error_msg')}
                    notif_number_validation_error_msg={t('forms:notif_number_validation_error_msg')}
                    notif_message_validation_error_msg={t('forms:notif_message_validation_error_msg')}

                    form_required_field_msg={t('forms:global_label_required_field_msg')}
                  />
                </div>
              </div>
              <div className="text_block">
                <div className="av-content">
                  <h2 className="mb-4">
                    {t('main:home_contact_form-sidechat-title_p1' )}
                    <br /> {t('main:home_contact_form-sidechat-title_p2' )}
                  </h2>
                  <p>
                  {t('main:home_contact_form-sidechat-text_p1' )}{" "}
                    <br />
                    {t('main:home_contact_form-sidechat-text_p2' )}
                  </p>
                  <p>
                  {t('main:home_contact_form-sidechat-text_p3' )}
                    <br /> {t('main:home_contact_form-sidechat-text_p4' )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-reference" id="partners2">
          <div className="container">
            <h1 className="sec-title"> {t('title_our_partners' )} </h1>
            <div className="ref-slider">
            <div className="slider-block responsive-two">
              <Slider {...references_slider_settings}>
                <div className="home-reference-row">
                   <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'partner', "amazon-web-services")} title={t('main:home_partners_logo-link-title_txt',{partner: "AWS"})}><img src={img_logo_aws} alt={t('main:home_partners_logo-alt_txt',{partner: "Amazon Web Services"})}/></a>
                </div>
                <div className="home-reference-row">
                    <a href="https://www.devo.com/" target="_blank" title={t('main:home_partners_logo-link-title_txt',{partner: "Devo"})}><img src={img_logo_devo} alt={t('main:home_partners_logo-alt_txt',{partner: "Devo"})}/></a>
                </div>
                <div className="home-reference-row">
                    <a href="https://www.reblaze.com" target="_blank" title={t('main:home_partners_logo-link-title_txt',{partner: "Reblaze"})}><img src={img_logo_reblaze} alt={t('main:home_partners_logo-alt_txt',{partner: "Reblaze"})}/></a>
                </div>
                <div className="home-reference-row">
                    <a href="https://fr.tdsynnex.com" target="_blank" title={t('main:home_partners_logo-link-title_txt',{partner: "TD Synnex"})}><img src={img_logo_tdsynnex} alt={t('main:home_partners_logo-alt_txt',{partner: "TD Synnex"})}/></a>
                </div>
              </Slider>
            </div>

            </div>
          </div>
        </div>
    </Layout>
  )
}

export default IndexPage
